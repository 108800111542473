.table-wrapper {
  height: 1500px; /* Define the height for the table body */
  overflow-y: auto; /* Enable vertical scrolling */
}

.table-wrapper table {
  width: 100%;
}

.table-wrapper thead th {
  position: sticky;
  top: 0;
  background-color: white;

  border-bottom: 2px solid #ddd; /* Optional: Add a border for header */
}
